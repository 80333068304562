<template>
    <div class="page">
        <el-form size="small" class="query-form searchForm" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="borrowCodeNumber" label="借阅单号：">
                <el-input v-model.trim="searchForm.borrowCodeNumber" maxlength="50" placeholder="请输入借阅单号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="borrowTime" label="借阅时间：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.borrowTime"
                        type="date"
                        placeholder="请选择借阅时间" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="deadline" label="规划期限：">
                <el-date-picker
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        v-model="searchForm.deadline"
                        type="date"
                        placeholder="请选择期限" class="w100i">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="borrowUserId" label="借阅人：">
                <el-select v-model="searchForm.borrowUserId" filterable placeholder="请选择" clearable class="w100i">
                    <el-option v-for="item in userList" :key="item.userId" :label="item.name"
                               :value="item.userId"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item prop="borrowPurpose" label="借阅目的：">
                <el-select v-model="searchForm.borrowPurpose" placeholder="请选择" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('borrow_purpose')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="keyWord" label="文档名称：">
                <el-input v-model.trim="searchForm.keyWord" maxlength="50" placeholder="输入文档名称搜索"
                          clearable></el-input>
            </el-form-item>
            <div class="flex_b_c p_b2">
                <div>
                    <el-button size="small" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                    <el-button size="small" icon="el-icon-refresh-right" @click="reset()">重置</el-button>
                </div>
                <div>
                    <el-button type="primary" size="small" icon="el-icon-upload2"
                               v-if="hasPermission('interior:operator:lend')" @click="lend()">批量借出
                    </el-button>
                    <el-button type="primary" size="small" icon="el-icon-set-up" @click="utilization(null, 'add')">内部利用
                    </el-button>
                    <el-button type="primary" size="small" icon="el-icon-monitor" v-show="hasPermission('interior:apply:myAudit')"
                               @click="myAudit()">我的审核
                    </el-button>
                </div>
            </div>
        </el-form>
        <div class="bg-white">
            <el-tabs v-model="searchForm.lendState" size="small" @tab-click="search(1)">
                <el-tab-pane :label="item.label" :name="item.value" v-for="(item, index) in tabsList" :key="index"></el-tab-pane>
            </el-tabs>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100vh - 452px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable" row-key='id'>
                <el-table-column type="selection" :reserve-selection='true' :selectable="(row) => {return row.lendState == '1'}" fixed/>
                <el-table-column prop="borrowCodeNumber" label="借阅单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowUserIdName" label="借阅人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowTime" label="借阅时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="deadline" label="规划期限" show-overflow-tooltip></el-table-column>
                <el-table-column prop="borrowPurpose" label="借阅目的" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("borrow_purpose", scope.row.borrowPurpose ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="auditState" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("audit_state", scope.row.auditState ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="lendUserName" label="借阅经办人" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="200" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small"
                                   v-show="hasPermission('interior:apply:view')"
                                   @click="addEdit(scope.row, 'view', scope.$index)">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('interior:operator:lend') && scope.row.lendState == '1'"
                                   @click="lend(scope.row.id)">借出
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('interior:operator:return') && scope.row.lendState == 0 && scope.row.returnState != 0"
                                   @click="returnData(scope.row.id)">归还
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <OperatorForm ref="operatorForm" @refreshDataList="search(0)"></OperatorForm>
    </div>
</template>

<script>
    import OperatorForm from './operatorForm'

    export default {
        components: {OperatorForm},
        data() {
            return {
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                searchForm: {
                    borrowCodeNumber: '',
                    borrowTime: '',
                    deadline: '',
                    borrowUserId: '',
                    borrowPurpose: '',
                    keyWord: '',
                    lendState: '-1',
                    borrowType: '3',
                    current: 1,
                    size: 10,
                },
                userList: [],
                tabsList: [],
                loading: false,
                dataList: [],
                total: 0,
                dataListSelections: [],
            }
        },
        mounted() {
            this.tabsList = this.$dictUtils.getDictList('lend_state')
            this.tabsList.unshift({
                value: '-1',
                label: '全部'
            })
            this.search()
        },
        methods: {
            // 查询
            search(type) {
                this.getUserList()
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                if(p.lendState == '-1') {
                    p.lendState = ''
                }
                this.$axios(this.api.record.lendList, p, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 新增
            addEdit(row, method, index) {
                let searchForm = JSON.parse(JSON.stringify(this.searchForm))
                if(searchForm.lendState == '-1') {
                    searchForm.lendState = ''
                }
                searchForm.index = (searchForm.current - 1) * searchForm.size + (index + 1)
                this.$refs.operatorForm.init(row, method, searchForm)
            },
            // 归还
            returnData(id) {
                let ids = [id];
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定归还吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.entityReturns, ids, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('归还成功')
                            this.search()
                        } else {
                            this.$message.success('归还失败')
                        }
                    })
                })
            },
            // 借出
            lend(id) {
                let ids = [id];
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (!id) {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    })
                }
                this.$confirm(`确定借出？确定后表示借阅人已拿到本次申请的全部实体档案`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.entityLends, ids, 'post').then(data => {
                        if (data.status) {
                            this.$message.success('借出成功')
                            this.search()
                        } else {
                            this.$message.success('借出失败')
                        }
                    })
                })
            },
            // 我的审核
            myAudit() {
                this.$router.push({name: 'entityInteriorAuditorList'})
            },
            // 内部利用
            utilization() {
                this.$router.push({path: '/record/borrow/entity/interior/applyList'})
            },
            // 重置
            reset() {
                this.$refs.multipleTable.clearSelection()
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 获取用户列表
            getUserList() {
                let p = JSON.parse(JSON.stringify(this.searchForm))
                if(p.lendState == '-1') {
                    p.lendState = ''
                }
                this.$axios(this.api.record.responsibleBorrow, {
                    borrowType: '3',
                    lendState: p.lendState,
                }, 'post').then(data => {
                    if (data && data.status) {
                        this.userList = data.data
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped lang="scss">
    .searchForm {
        .el-form-item--small.el-form-item {
            display: inline-block;
            width: 25%;
        }
    }
</style>
